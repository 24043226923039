

// ::v-deep img {
//   width: 100%;
// }

.product-detail {
  margin-top: 150px;
  @media small {
    margin-top: 200px;
  }
}

.color-content {
  display: grid;
  gap: 16px 4px;
  grid-template-columns: repeat(8, 1fr);

  @media small {
   display: flex;
   justify-content: flex-start;
   flex-wrap: wrap !important;
   background: white;
   padding: 0
   gap 24px
  }
}

.capacity-content {
  display: grid;
  gap: 16px;
  grid-template-columns: repeat(1, 1fr);

  @media screen and (max-width: 768px) {
     grid-template-columns: repeat(1, 1fr);
  }
}

.condition {
    display: block;
    overflow: hidden;
    border-radius: 8px
    background: $color-bnn-gray-light-2
    padding: 0.75rem 1rem
    @media small {
      overflow: scroll;
      max-height: 120px
    }
}

.text-label {
  color: $color-bnn-gray-medium-2;
  font-weight: bold
}

.price-summary {
  color:  $color-text-price
  color:  $color-bnn
  font-weight: bold
  font-size: 1rem
  text-align: right
  // line-height: 2.25rem
}

.price-summary-deposit {
  color:  $color-bnn
  font-weight: bold
  font-size: 1rem
  text-align: right
  // line-height: 2.25rem
}

.text-bnn-red-2 {
  color: $color-bnn-red-2
}

.text-ship-date {
    color: $color-bnn-blue-2
    font-weight: bold
}

.gallery {
    display: flex
    justify-content: center
    flex-direction: column
    justify-content: flex-start
    padding-right: 5rem
    margin-top: -2.2rem
    @media screen and (max-width: 960px) {
      padding: 0
      display: flex
      justify-content: center
      width: 100%
    }
}

.harry-up
    color: $color-text-harry-up

.preview-html
  overflow: hidden

@media screen and (max-width: 768px)
  .gallery
    padding 0

.button-to-cart-action
    margin-left: -12x !important
    margin-right: -12px !important
    // @media screen and (max-width: 768px)
    //   margin-left: 0 !important
    //   margin-right: 0 !important
    //   position: fixed
    //   z-index 50
    //   width: 100%
    //   bottom: 0
    //   left: 0
    //   background: #fff
    //   box-shadow: 1px 1px 2px 1px $color-gray-200;

.wrap-btn-action
  display: flex
  flex-wrap: wrap
  gap: 0.75rem

.wrap-btn-action-case
  width: 100%
  display: flex
  flex-wrap: wrap
  gap: 0.75rem
  @media screen and (min-width: 768px)
    flex-wrap: nowrap;
    gap: 1rem
  .btn-action-item
    flex-basis: 100%;
    @media screen and (min-width: 768px)
      flex-basis: 50%;
      &.full
        flex-basis: 100%;

@media screen and (max-width: 960px)
  .footer-margin
    margin-bottom: 150px;


.product-detail-layout
  display: flex
  justify-content: center
  flex-direction: column
  // margin-right: -12px
  // margin-left: -12px
  padding-top: 22px
  .pdp-preview-swiper
    // width: 50%
    width: 100%
    @media screen and (min-width: 48rem)
      width: 50%
    @media screen and (min-width: 61.875rem)
      padding-top: 0
      width: 63.5%
  .pdp-content
    width: 100%
    @media screen and (min-width: 768px)
        padding: 12px
        padding-left: 48px
        padding-right: 0px
    @media screen and (min-width: 48rem)
      width: 50%
    @media screen and (min-width: 61.875rem)
        width: 36.5%
        padding-left: 48px
  @media screen and (min-width: 48rem)
    flex-direction: row


.btn-deposit {
    padding: 0.75rem !important
    // padding: 1.25rem 0 !important
    font-weight: bold !important
    font-size: 0.875rem !important
    background: $color-deposit !important

    &.disabled {
      pointer-events: none;
      cursor: not-allowed;
      opacity: 0.5
    }

}

.full
  .btn-deposit
    background: $color-bnn !important
    .text-deposit
      color: $color-bnn-btn-text !important
      font-size: 1rem !important
  .full-payment-content
    font-size: 1rem !important


.btn-bnn-default
  padding: 0.75rem !important

.text-deposit
    color: $color-deposit-text

.deposit-content,.full-payment-content
  width: 100%
  display: flex
  justify-content: center
  gap: 8px
  align-items: baseline;
  // padding: 0 2rem
  white-space: nowrap

.product-label-layout
  display: flex
  flex-wrap: auto

.action-contain-layout
  display flex
  width: 100%
  flex-direction: row
  justify-content: space-between
  flex-wrap: wrap
  padding: 0.5rem 0 2px 0
  .soldOut
    width: 100%
    padding: 12PX
  .deposit
    width: 50%
    padding: 12PX
    @media screen and (max-width: 960px)
      width: 100%
    @media small
      padding 4px 12px
  .full
    width: 50%
    padding: 12PX
    @media screen and (max-width: 960px)
      width: 100%
    @media small
      padding 4px 12px
  .full-employee
    width: 100%
    padding: 12PX
    cursor: pointer
    .text-employee
      font-weight: bold
    .text-payment
      margin-left: 0px
      font-weight: bold
      color: $color-bnn
  @media screen and (max-width: 960px)
    flex-direction: row
    align-items: center

.w-full
  width: 100% !important

.ship-date-summary-price-layout
  display flex
  justify-content: space-between
  width: 100%
  @media tablet
    flex-direction: row
    align-items: flex-start
    justify-content: space-between
    text-align: center
  align-items: flex-start
  .ship
    max-width: 100%
    color: $color-bnn-border-dark-btn
    .ship-date
      font-weight: bold
      color: $color-bnn-border-dark-btn
    @media tablet
      // padding: 0.5rem 0
      width: 100%

  .price
    color: $color-bnn-border-dark-btn
    @media tablet
      text-align: right
      padding: 0.5rem 0
      width: 100%

.desktop-only
  display: block
  @media tablet
    display: none

.html-content
  overflow: auto
  width: 100%
  min-width: 100%
  justify-content: center;

.hurry-up-label
  display: block

.model-container
  @media small
    display: grid
    grid-template-columns: repeat(1, 1fr)
    gap: 0 1rem

.campaign-name-content {
  @media small {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    // margin-top: -100px
    padding 1rem 0;
  }
}

.cart-fixed {
  //@media small {
    position: fixed
    -webkit-transform: translateZ(0);
    transform: translateZ(0);
    z-index: 50
    width: 100%
    bottom: 0
    left:0
    padding: 1rem


    background: #f2f2f2
    box-shadow: 1px 1px 2px 1px $color-gray-200;
  //}
}

.ship-date-row {
  margin-top: 0
  // @media small {
  //   margin-top: -18px
  // }
}

.model-wrapper {
  width: 100%

  @media small {
    // width: calc(100% + (1.5rem))
    // min-width: calc(100% + (1.5rem))
    // margin-left: -1.25rem
    // padding-left: 1.25rem
    // padding-top: 1rem
    // background-color: $color-bnn-gray-light-2
  }
}

.color-wrapper{
  width: 100%

  @media small {
    // width: calc(100% + (1.75rem))
    // min-width: calc(100% + (1.75rem))
    // margin-left: -1.25rem
    // padding-left: 1.25rem
    // background-color: $color-bnn-gray-light-2
  }
}

.capacity-wrapper {
  width: 100%

  @media small {
    // width: calc(100% + (1.75rem))
    // min-width: calc(100% + (1.75rem))
    // margin-left: -1.25rem
    // padding-left: 1.25rem
    // background-color: $color-bnn-gray-light-2
    // padding-bottom: 1rem
  }
}

.is-mobile {
  display: none
  @media screen and (max-width: 600px) {
    display: block
  }
}

.is-desktop {
  display: block
  @media screen and (max-width: 600px) {
    display: none
  }
}

.title-also-bought {
  padding-top: 1rem
  font-weight: bold
  font-size: 1.25rem
}

.select-by-sku
  .btn-model.disabled
    color: $color-bnn-gray-medium-2
  .btn-model.disabled,
  .btn-color.disabled,
  .btn-spec.disabled
    event-pointer: none
    cursor: not-allowed

.v-btn--disabled.v-btn--outlined
  opacity 0.45
  border-color: $color-bnn !important
  color: $color-bnn-btn-text !important
  .text-deposit
    color: $color-bnn !important

.theme--light.v-btn.v-btn--disabled:not(.v-btn--flat):not(.v-btn--text):not(.v-btn--outlined).btn-bnn-default
  opacity 0.45
  background-color: $color-bnn !important
  color: $color-bnn-btn-text !important


.pdp-content-container{
    display: flex;
    flex-direction: column;
    gap: 48px;
    max-width: 100dvw;
}
.badge-pre-order {
    color: #336e7b;
    font-weight: 600
}

::v-deep .v-btn.btn-link.promotion-privileges {
    padding 0 !important
    color: #bf4800
    cursor pointer

    &:hover {
        color: $color-bnn

        .v-icon {
            color: $color-bnn
        }
    }

    &:hover:before {
        background-color: transparent !important
    }

    &:focus {
        &:before {
            background-color: transparent !important
        }
    }

    &::after {
        background-color: transparent !important
    }

    .v-icon {
        color: #bf4800
    }
}
