
.product-detail-primary-label
  display: flex
  align-items: center
  width: fit-content
  margin-right .75rem
  margin-top .5rem
  border-radius .5rem
  font-size .875rem
  line-height 1.5

.product-detail-primary-label>.image
    width: 32px
    height 32px
    object-fit: contain

.product-detail-primary-label.-can-see-detail
   padding: .25rem 0 .25rem .25rem
   background-color $color-bnn-gray-light-2
   cursor pointer
.product-detail-primary-label.-can-see-detail>.cta
   font-weight: bold
   padding  0 .5rem

.w-full
    width: fit-content
