
.text-desc {
    font-size: 0.9rem
    color: $color-bnn-gray-medium-2
}

.title-modal {
    display: flex
    justify-content:flex-start
    flex-direction: column
}

.term-content
    background-color: $color-bnn-gray-light-2
    width: 100%
    height: 100%
    max-height: 40dvh
    overflow: auto
    border-radius: 4px
    padding: 1rem
    @media small
      padding: 1rem
    @media medium
      padding: 1rem


.term-action {
    display: flex
    flex-direction: column
    width: 100%
}

.text-label-term
  color: $color-bnn
  text-decoration: underline;
  margin: 0 1px

.btn-cancel
  font-weight: bold !important
  font-size: 0.875rem !important
  padding: 18.5px 0 !important
  border-color: $color-secondary !important

.text-cancel
  color: $color-secondary-text

.price-wrapper
  display: grid
  grid-template-columns: repeat(2, 1fr)
  padding: 8px 0
  text-overflow: ellipsis;
  white-space: nowrap;
  overflow: hidden;
  .text-price-md
    font-size: 1rem
    text-overflow: ellipsis
  .text-price-lg
    font-size: 1.125rem
    font-weight: bold
    text-overflow: ellipsis
.footer-modal
  font-size: 0.875rem
  color: rgba(0, 0, 0, 0.6)
  padding: 0
  width: 100%
  margin-top: 1rem

.is-mobile-none
  @media small
    display: none !important
.is-desktop-none
  display: none
  @media small
    display: block !important
.close
  display: flex
  justify-content: flex-end
  position: absolute
  right: 1rem
  top: 1rem

.v-card__actions
  margin-top: -1rem;

.link
    color: #1976d2
    cursor: pointer
.text-price-lg
	font-size: 20px
	font-weight: bold
	text-overflow: ellipsis

.action-container
	display: flex
	align-items: flex-end
	justify-content: flex-end
	flex-direction: column
	gap: 20px
	// padding: 20px
	// border-top: 1px solid #f0f0f0
	@media min-medium
		flex-direction: row
	.price-container
		display: grid
		align-items: center
		grid-template-columns: repeat(1, 1fr)
		gap: 8px
		width: 100%
		@media min-medium
			gap: 0
			grid-template-columns: repeat(2, 1fr)
		.price-box
			display: flex
			justify-content: space-between
			align-items: center
			flex-flow: row wrap
			@media min-medium
				flex-flow: column wrap
				align-items: flex-start
		.price-box:nth-child(2)
			border-right: none
			@media min-medium
				padding-left: 20px
				border-left: 1px solid #f0f0f0\

	.btn-container
		width: fit-content
		max-width: 100%
.info-container
	font-size: 12px
	.v-icon
		font-size: 12px
		color: #808080


.modal-card {
    padding: 24px
    border-radius: 18px
}

.header-modal {
    display: flex
    justify-content: space-between
    align-items: center
    margin-bottom: 16px
}

.btn-close {
    width: 40px;
    height: 40px;
    min-width 40px

    background: #e8e8e8;
    border-radius: 50% !important;

    z-index 10

    &:hover {
        .icon-close {
            color: rgba(0,0,0,0.72)
        }
    }

    &:focus {
        outline: .125rem solid #0071e3;
        outline-offset: .125rem;
        outline-offset: .125rem;
    }

    .icon-close {
        color: rgba(18,18,18,0.56)
    }
}
