

.btn-spec
    // border: 1px solid $color-bnn-gray-light
    height: 54px;
    border-radius: 8px;
    width: 100%
    display: flex
    // justify-content: center
    // align-items: center
    padding: 1rem
    // font-weight: bold
    // text-align: center
    box-shadow: 0 0 0 1px $color-bnn-gray-light;
    transition: 0.2s ease-in-out;
    @media small
      background: white
    @media (hover: hover) and (pointer: fine)
      &:hover:not(.disabled):not(.select)
        //border-color: transparent;
        box-shadow: 0 0 0 1px $color-bnn-border;
        cursor: pointer
        transition: 0.2s ease-in-out;

.btn-spec.disabled {
  // opacity: 0.5;
  color: #12121299;
  border: 1px dashed $color-bnn-gray-light;
  background: #f7f7f7b3;
  box-shadow: none;
  &.select {
    color: #12121299;
    border: 1px dashed $color-border-btn-spec;
    background: #f7f7f7b3;
    box-shadow: none;
  }
  @media (hover: hover) and (pointer: fine){
    &:hover{
        color: #12121299;
        border: 1px dashed $color-border-btn-spec;
        background: #f7f7f7b3;
        box-shadow: none;
    }
  }
}

.btn-spec.select {
  box-shadow: 0 0 0 2px $color-border-btn-spec;
  //border: 1px solid $color-border-btn-spec;
}
