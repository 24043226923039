

.apple-care-card
    color: $color-bnn-gray-medium
    position relative
    border: 1px solid $color-bnn-gray-light
    background white
    border-radius: 8px;
    display: flex
    justify-content: center
    align-items: center
    flex-wrap: wrap
    flex-direction: column
    padding: 0 1rem
    margin: 0.5rem 0
    height 50px
    cursor pointer
    .title
        width: 100%
        text-align: center
        font-size: 1rem !important
        font-weight: bold
        // margin 1rem 0
        display: flex
        justify-content: center
        gap 1rem
        align-items: center
        min-height: 60px
        font-family: $font-family-base !important
    .content
        display: flex
        flex: 1 1 auto
        padding: 1rem 0
        gap 1rem
        .apple-care-image
            width: 64px
            height: 64px
            object-fit: contain
            img
                width 100%
                min-width: 64px
        .desc
            color: $color-gray-2
            .price
                display: flex
                justify-content: flex-start
                position: relative
                align-items: center
                margin-top 1rem

.apple-care-card.selected
    border: 1px solid $color-bnn

.apple-care-card.disabled
    opacity 0.5

.text-black
    color: $color-bnn-gray-medium

.text-price
    color: $color-text-price

.apple-care-card-cart
	.content
		display: flex
		flex-wrap: wrap
		flex: 1 1 auto
		padding: 12px
		gap 1rem
		background-color: #f7f7f7
		border-radius: 8px
		.apple-care-image
			width: 48px
			height: 48px
			object-fit: contain
			img
				width 100%
				min-width: 48px
		.desc
			flex: 1
			font-size: 14px
			.price
				display: flex
				justify-content: flex-start
				position: relative
				align-items: center
				margin-top: 4px
		.btn-toggle
			width: 100%
			flex: auto
			@media min-medium
				min-width: 105px
				width: fit-content
				flex: 0
			.btn-apple-care-toggle
				min-width: 105px
				font-weight: bold
				color: #2d2d2d
				.v-icon
					font-size: 14px
					margin-right: 4px
				&.selected
					color: white
		.text-white
			color: white

.srp-pricing
  color: rgba(0, 0, 0, 0.45)
  margin-left: 8px
