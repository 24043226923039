

.title-modal-layout
    display: flex
    justify-content: space-between
    width: 100%
    .close
        cursor: pointer

.free-gifts
    background: $color-bnn-gray-light-2 0% 0% no-repeat padding-box;
    border-radius: 8px;
    display: flex
    justify-content: space-between
    flex: 1 1
    padding: 1rem
    margin-top: 1rem
    flex-direction: column
    @media screen and (max-width: 768px)
      padding: 1rem 0.5rem
      font-size: 0.85rem

.free-gift-item
    display: flex
    padding .5rem 0

.free-gift-item .item-desc
    display flex
    width 75%

.free-gift-item .item-desc .image-placeholder, .free-gift-item .item-desc .image-wrapper
    flex-shrink: 0

.free-gift-item .item-desc>.inline-grid
    margin-left 1rem
    display: inline-grid
    align-items center
    color: $color-secondary=-text

.free-gift-item .item-desc .image, .free-gift-item .item-desc .image-placeholder
    width: 3.5rem
    height: 3.5rem

.free-gift-item .item-price
    font-weight bold
    width: 25%
    line-height 1.5
    text-align right
    color $color-text-price
    font-size 1rem

.free-gift-item .item-desc>.inline-grid>.name
    font-size 1rem
    line-height 1.2

.free-gift-item .item-desc>.inline-grid>.limit
    font-size: .75rem
    color: rgba(0,0,0,.45)
    line-height 1.3

.free-gifts-remark
    color: rgba(0,0,0,.45)
    font-size: .75rem
    margin-bottom: 0
    margin-top: 0.75rem
    line-height: 1.3
