

.accessory-bundle
    color: $color-bnn-gray-medium
    position relative
    // border: 1px solid $color-bnn-gray-light
    background white
    border-radius: 8px;
    display: flex
    justify-content: center
    flex-wrap: wrap
    flex-direction: column
    padding: 0 1rem
    margin-bottom: 1rem
    height 50px
    cursor pointer
    box-shadow: 0 0 0 1px $color-bnn-gray-light;
    transition: 0.2s ease-in-out;
    @media (hover: hover) and (pointer: fine)
      &:hover:not(.disabled)
        box-shadow: 0 0 0 2px $color-bnn-border;
        cursor: pointer
        transition: 0.2s ease-in-out;
    &:last-child
        margin-bottom: 0
    .warp-title
        width: 100%
        display: flex
        justify-content: flex-start
        flex-wrap: nowrap
        gap 1rem
        align-items: center
        // text-align: center
        // margin 1rem 0
        //justify-content: center
        // min-height: 60px
        .image
          width: 36px
          height: 36px
          aspect-ratio: 1/1
          display: flex
          img
            width: 100%
            height: 100%
            max-width: 36px
            max-height: 36px
            object-fit: contain
            -webkit-object-fit: contain
            object-position: center
            -webkit-object-position: center
        .title
          flex: auto
          font-size: 1rem !important
          font-family: $font-family-base !important
          line-height: 1
          font-weight: bold
        .price
          font-size: 1rem !important
          font-family: $font-family-base !important
          line-height: 1
          font-weight: bold
    .content
        display: flex
        flex: 1 1 auto
        padding: 1rem 0
        gap 1rem
        .apple-care-image
            width: 64px
            height: 64px
            object-fit: contain
            img
                width 100%
                min-width: 64px
        .desc
            color: $color-gray-2
            .price
                display: flex
                justify-content: flex-start
                position: relative
                align-items: center
                margin-top 1rem

.accessory-bundle.selected
    // border: 1px solid $color-bnn
    box-shadow: 0 0 0 1.5px $color-border-btn-spec;

.accessory-bundle.disabled
    opacity 0.5

.text-black
    color: $color-bnn-gray-medium

.text-price
    color: $color-text-price
