@media (min-width: 992px) {
  .gallery-md {
    margin-top: 0.5rem;
    display: flex;
    flex-wrap: wrap;
    width: 490px;
  }
  .relative-md {
    position: relative !important;
  }
  .absolute-md {
    position: absolute !important;
  }
  .float-left-md {
    float: left;
  }
  .sticky-md {
    position: fixed !important;
  }
  .mw-490 {
    min-width: 80px !important;
  }
  .mh-621 {
    min-height: 142px !important;
  }
  .top-md-0 {
    top: 20px;
  }
  .h-md-2 {
    height: 0.5rem;
  }
  .pd-0 {
    padding: none;
  }
  .pd-1 {
    padding: none;
  }
  .pdr-0 {
    padding-right: 0rem !important;
  }
  .pdr-1 {
    padding-right: 0.5rem !important;
  }
  .pdr-2 {
    padding-right: 1rem !important;
  }
  .pdr-3 {
    padding-right: 1.2rem !important;
  }
  .pdr-4 {
    padding-right: 1.5rem !important;
  }
  .pdr-5 {
    padding-right: 2rem !important;
  }
  .pdr-6 {
    padding-right: 3rem !important;
  }
  .pdl-0 {
    padding-left: 0rem !important;
  }
  .pdl-1 {
    padding-left: 0.5rem !important;
  }
  .pdl-2 {
    padding-left: 1rem !important;
  }
  .pdl-3 {
    padding-left: 1.2rem !important;
  }
  .pdl-4 {
    padding-left: 1.5rem !important;
  }
  .pdl-5 {
    padding-left: 2rem !important;
  }
  .pdl-6 {
    padding-left: 3rem !important;
  }
}
.align-flex-end {
  align-items: flex-end;
}
.justify-content-between {
  justify-content: space-between;
}
.text-underline {
  text-decoration: underline;
}
.text-red {
  color: #ff4252;
}
.text-blue {
  color: #0671e3;
}
.text-green {
  color: #5bba47;
}
.text-primary {
  color: #0071e3;
}
.text-primary-mid {
  color: rgba(0,113,227,0.451);
}
.text-primary-light {
  color: rgba(0,113,227,0.051);
}
.bg-primary {
  background-color: #0071e3;
}
.bg-primary-mid {
  background-color: rgba(0,113,227,0.451);
}
.bg-primary-light {
  background-color: rgba(0,113,227,0.051);
}
.text-black {
  color: #272727;
}
.text-pre-order {
  color: #336e7b;
}
.text-line-through {
  text-decoration: line-through;
}
.pd-0 {
  padding: 0 0em;
}
.pd-1 {
  padding: 0 0.25em;
}
.bg-gray {
  background-color: #52514e;
}
.bg-gray-light {
  background-color: #ebebe3;
}
.bg-green-midnight {
  background-color: #697b70;
}
.bg-gold {
  background-color: #ffd6b9;
}
hr.gray {
  background-color: #e2e8f0;
  height: 1px;
  border: 0;
}
.w-full {
  width: 100%;
}
.w-fit-content {
  width: fit-content;
}
.font-bold {
  font-weight: bold;
}
.font-light {
  font-weight: 300;
}
.font-normal {
  font-weight: 400;
}
.font-medium {
  font-weight: 500;
}
.fit-contain {
  object-fit: contain;
}
.font-semibold {
  font-weight: 600;
}
.text-dark-gray {
  color: #434242;
}
.text-white {
  color: #fff !important;
}
.text-bnn-blue {
  color: #338df7;
}
.text-gray-1 {
  color: $color-gray-1;
}
.text-gray-2 {
  color: #666;
}
.text-gray-3 {
  color: $color-gray-3;
}
.text-gray-4 {
  color: $color-gray-4;
}
.dp-none {
  display: none;
}
.dp-block {
  display: block;
}
@media screen and (min-width: 768px) {
  .dp-md-none {
    display: none;
  }
  .dp-md-flex {
    display: flex;
  }
}
@media screen and (min-width: 768px) {
  .dp-md-block {
    display: block;
  }
}
@media screen and (min-width: 1024px) {
  .dp-lg-none {
    display: none;
  }
}
@media screen and (min-width: 1024px) {
  .dp-lg-block {
    display: block;
  }
}
.dp-lg-flex {
  display: none !important;
}
@media screen and (min-width: 1024px) {
  .dp-lg-flex {
    display: flex !important;
  }
}
.text-label-summary {
  font-size: 1.5rem;
}
.text-cost-summary {
  font-size: 1.875rem;
}
@media (max-width: 768px) {
  .text-label-summary {
    font-size: 1.125rem;
  }
  .text-cost-summary {
    font-size: 1.325rem;
  }
}
.ship-date-highlight {
  color: #ff4252 !important;
  font-weight: 500 !important;
}
.ship-date-highlight.lot-2 {
  color: #5bba47 !important;
}
.v-toast__item--success {
  background: #5bba48 !important;
}
.v-btn {
  border-radius: 32px !important;
}
.v-btn.v-size--default {
  height: 50px;
  padding: 14px 30px !important;
}
.v-btn.v-btn--outlined {
  border-width: 2px;
}
.v-btn.v-btn--text.btn-link {
  height: fit-content;
  padding: 0 !important;
  font-weight: 600;
  line-height: 1.125rem;
  letter-spacing: -0.015rem;
  text-decoration: none;
}
.v-btn.v-btn--text.btn-link:hover {
  background: none;
}
.v-btn.v-btn--text.btn-link .v-icon {
  font-size: 20px;
}
.v-btn.v-size--default:not(.btn-link):not(.v-btn--contained) {
  height: 50px;
}
.v-btn.v-size--default.btn-apple-care-toggle:not(.btn-link):not(.v-btn--contained) {
  width: 75px;
  height: 35px;
  padding: 6px !important;
  border-width: 1.5px !important;
}
.v-btn.v-size--default.btn-apple-care-toggle:not(.btn-link):not(.v-btn--contained):hover {
  background-color: none;
}
.v-btn.v-size--default.btn-apple-care-toggle:not(.btn-link):not(.v-btn--contained):hover:before {
  background-color: none;
}
.v-btn.v-size--large {
  height: 56px;
  padding: 14px 30px !important;
  font-weight: 600;
  line-height: 18px;
}
.v-dialog:not(.v-dialog--fullscreen) {
  border-radius: 18px;
}
.v-dialog__content {
  border-radius: 18px !important;
}
.v-text-field--outlined {
  border-radius: 5px !important;
}
.v-dialog__content.v-dialog__content--active {
  backdrop-filter: blur(20px);
}
.v-overlay__scrim {
  background: rgba(0,0,0,0.48);
}
.row {
  margin-top: 0;
  margin-bottom: 0;
}
body.freeze-overflow {
  overflow-y: hidden;
}
.full-page {
  width: 100dvw;
  min-height: calc(100dvh - (62px + 50px));
  margin-top: 62px !important;
  overflow-x: hidden;
}
.full-page.content-center {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
}
@media screen and (min-width: 768px) {
  .full-page {
    min-height: calc(100dvh - (92px + 450px));
  }
}
@media screen and (min-width: 1024px) {
  .full-page {
    min-height: calc(100dvh - (92px + 576px));
    margin-top: 88.625px !important;
  }
}
@media screen and (min-width: 1440px) {
  .full-page {
    min-height: calc(100dvh - (92px + 600px));
  }
}
.display-l {
  font-family: "SF Pro Display";
  font-size: 48px;
  font-style: normal;
  font-weight: 600;
  line-height: 58px;
}
.display-m {
  font-family: "SF Pro Display";
  font-size: 36px;
  font-style: normal;
  font-weight: 600;
  line-height: 42px;
}
.display-s {
  font-family: "SF Pro Display";
  font-size: 32px;
  font-style: normal;
  font-weight: 600;
  line-height: 36px;
}
.headline-l {
  font-family: "SF Pro Display";
  font-size: 24px;
  font-style: normal;
  font-weight: 600;
  line-height: 30px;
  letter-spacing: 0.48px;
}
@media screen and (max-width: 767px) {
  .headline-sm-l {
    font-family: "SF Pro Display";
    font-size: 24px;
    font-style: normal;
    font-weight: 600;
    line-height: 30px;
    letter-spacing: 0.48px;
  }
}
.headline-m {
  font-family: "SF Pro Display";
  font-size: 20px;
  font-style: normal;
  font-weight: 600;
  line-height: 26px;
  letter-spacing: -0.02em;
}
.headline-s {
  font-family: "SF Pro Display";
  font-size: 18px;
  font-style: normal;
  font-weight: 600;
  line-height: 20px;
  letter-spacing: 0.36px;
}
.title-bold {
  font-family: "SF Pro Text";
  font-size: 16px;
  font-style: normal;
  font-weight: 700;
  line-height: 20.002px;
}
.title-semibold {
  font-family: "SF Pro Text";
  font-size: 16px;
  font-style: normal;
  font-weight: 600;
  line-height: 20.002px;
}
.title-medium {
  font-family: "SF Pro Text";
  font-size: 16px;
  font-style: normal;
  font-weight: 500;
  line-height: 20.002px;
}
.title-regular {
  font-family: "SF Pro Text";
  font-size: 16px;
  font-style: normal;
  font-weight: 400;
  line-height: 20px;
}
.body-s-semibold {
  font-family: "SF Pro Text";
  font-size: 14px;
  font-style: normal;
  font-weight: 600;
  line-height: 20.002px;
}
.body-s-medium {
  font-family: "SF Pro Text";
  font-size: 14px;
  font-style: normal;
  font-weight: 500;
  line-height: 16px;
}
.body-s {
  font-family: "SF Pro Text";
  font-size: 14px;
  font-style: normal;
  font-weight: 400;
  line-height: normal;
}
.label-l-semibold {
  font-family: "SF Pro Text";
  font-size: 12px;
  font-style: normal;
  font-weight: 600;
  line-height: 14px;
}
.label-l-medium {
  font-family: "SF Pro Text";
  font-size: 12px;
  font-style: normal;
  font-weight: 500;
  line-height: 16px;
}
.label-l-regular {
  font-family: "SF Pro Text";
  font-size: 12px;
  font-style: normal;
  font-weight: 400;
  line-height: 14px;
}
.label-s-semibold {
  font-family: "SF Pro Text";
  font-size: 8px;
  font-style: normal;
  font-weight: 600;
  line-height: 14px;
}
.label-s-regular {
  font-family: "SF Pro Text";
  font-size: 8px;
  font-style: normal;
  font-weight: 400;
  line-height: 14px;
}
.strikethrough {
  font-family: "SF Pro Text";
  font-size: 16px;
  font-style: normal;
  font-weight: 600;
  line-height: normal;
}
.divider-gray-83 {
  border: none;
  border-top: 1px solid #d2d2d7; /* Adjust the color as needed */
  margin: 0; /* Adjust the margin as needed */
}
.divider-gray-85 {
  border: none;
  border-top: 1px solid #d9d9d9; /* Adjust the color as needed */
  margin: 0; /* Adjust the margin as needed */
}
.title-modal-layout {
  display: flex;
  justify-content: space-between;
  width: 100%;
}
.title-modal-layout .close {
  cursor: pointer;
}
.free-gifts {
  background: #f8f8f8 0% 0% no-repeat padding-box;
  border-radius: 8px;
  display: flex;
  justify-content: space-between;
  flex: 1 1;
  padding: 1rem;
  margin-top: 1rem;
  flex-direction: column;
}
@media screen and (max-width: 768px) {
  .free-gifts {
    padding: 1rem 0.5rem;
    font-size: 0.85rem;
  }
}
.free-gift-item {
  display: flex;
  padding: 0.5rem 0;
}
.free-gift-item .item-desc {
  display: flex;
  width: 75%;
}
.free-gift-item .item-desc .image-placeholder,
.free-gift-item .item-desc .image-wrapper {
  flex-shrink: 0;
}
.free-gift-item .item-desc>.inline-grid {
  margin-left: 1rem;
  display: inline-grid;
  align-items: center;
  color: -text;
}
.free-gift-item .item-desc .image,
.free-gift-item .item-desc .image-placeholder {
  width: 3.5rem;
  height: 3.5rem;
}
.free-gift-item .item-price {
  font-weight: bold;
  width: 25%;
  line-height: 1.5;
  text-align: right;
  color: #0071e3;
  font-size: 1rem;
}
.free-gift-item .item-desc>.inline-grid>.name {
  font-size: 1rem;
  line-height: 1.2;
}
.free-gift-item .item-desc>.inline-grid>.limit {
  font-size: 0.75rem;
  color: rgba(0,0,0,0.45);
  line-height: 1.3;
}
.free-gifts-remark {
  color: rgba(0,0,0,0.45);
  font-size: 0.75rem;
  margin-bottom: 0;
  margin-top: 0.75rem;
  line-height: 1.3;
}
/*# sourceMappingURL=src/components/bnn/ModalLabelProduct.css.map */