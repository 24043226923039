

.btn-model
  height: 54px;
  margin: 1rem 0;
  border-radius: 8px;
  text-transform: none !important;
  // border: 1px solid $color-bnn-gray-light;
  width 100%
  display: flex
  justify-content: center
  align-items: center
  padding: 1rem;
  box-shadow: 0 0 0 1px $color-bnn-gray-light;
  border-radius: 8px;
  transition: 0.2s ease-in-out;
  &:last-child
    margin-bottom: 0

  @media small
    background: white;

  @media small
    padding: 0 1rem;
    margin: 0.5rem 0;
  @media (hover: hover) and (pointer: fine)
    &:hover:not(.disabled):not(.select)
      border-color: transparent;
      box-shadow: 0 0 0 1px $color-bnn-border;
      cursor: pointer
      transition: 0.2s ease-in-out;

  .price
    @media small
      display: none

.btn-model.disabled
     // cursor: not-allowed
     // opacity: 0.5
     color: #12121299
     border: 1px dashed $color-bnn-gray-light;
     background: #f7f7f7b3
     box-shadow: none

.btn-model.select
    box-shadow: 0 0 0 2px $color-border-btn-spec;
    // border: 1px solid $color-border-btn-spec;
.btn-model.disabled.select
     color: #12121299
     border: 1px dashed $color-border-btn-spec;
     box-shadow: none;
     @media (hover: hover) and (pointer: fine){
        &:hover{
            color: #12121299;
            border: 1px dashed $color-border-btn-spec;
            box-shadow: none;
        }
     }

.model-title
  text-align: left
  @media small
    text-align: center
